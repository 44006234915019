interface Location {
  hash: string
}

interface RouteUpdate {
  location: Location
  prevLocation: Object | null
}

const scrollToElement = require('scroll-to-element')

const checkHash = (location: Location) => {
  const { hash } = location
  if (hash) {
    scrollToElement(hash, {
      offset: -60,
      duration: 1000,
    })
  }
}

exports.onRouteUpdate = ({ location }: RouteUpdate) => {
  checkHash(location)
}
